.btn {
  appearance: none;
  display: inline-block;
  background: white none;
  border: none;
  line-height: 1;
  font-size: 1em;
  padding: 1em 0.5em;
  border-radius: 1.5em;
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;
}

.btn-primary {
  background-color: $color-primary;
  color: white;
}

.btn-secondary {
  background-color: $color-secondary;
  color: white;
}

.btn-block {
  display: block;
  width: 100%;
  text-align: center;
  margin: 1em 0;
}
