$color-primary: #ff0053;
$color-secondary: #3c096c;
$color-tertiary: #03bfb2;
$color-error: #9e0300;

$color-white: #f9f9f9;
$color-grey: #4f4f4f;

$media-query-small: 'only screen and (min-width: 576px)';
$media-query-medium: 'only screen and (min-width: 768px)';
$media-query-large: 'only screen and (min-width: 1024px)';
$media-query-x-large: 'only screen and (min-width: 1200px)';
