@import 'base';
@import 'buttons';
@import 'fonts';

@font-face {
  font-family: 'ZichtbaarBlack';
  src: url('../fonts/Zichtbaar/Zichtbaar-Black.otf') format('truetype');
}

@font-face {
  font-family: 'ZichtbaarItalic';
  src: url('../fonts/Zichtbaar/Zichtbaar-Italic.otf') format('truetype');
}

@font-face {
  font-family: 'ZichtbaarMedium';
  src: url('../fonts/Zichtbaar/Zichtbaar-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'ZichtbaarRegular';
  src: url('../fonts/Zichtbaar/Zichtbaar-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'ZichtbaarBold';
  src: url('../fonts/Zichtbaar/Zichtbaar-Bold.otf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
